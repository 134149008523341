import { customElement, property, state } from "lit/decorators.js";
import { LitElement, css, html } from "lit";
import { font } from "@/util/fonts";
import "@/generated/css/variables.css";
import { type } from "@/util/layout";
import "@/elements/mobile/fix-button";
import "@/elements/mobile/fix-button";
import "../layout/page-section-layout";
import { Url } from "@/helpers/url";
import { t } from "i18next";
import { localized } from "@/decorators/localized";

@customElement("main-banner")
@localized()
export class MainBanner extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" = "lg";

  render() {
    return html`
      <div class="main-banner">
        <div class="fader">
          <div class="title">
            ${t("asdfjlcnsakdjhfv.are-you-business-title", "Are you a business?")}
          </div>
          <div class="subtitle">
            ${t("uio234hl234hjk2h.are-you-business-text", "Sign up for a business account and gain access to exclusive benefits and personal manager!")}
          </div>
          <div class="btn-holder">
            <fix-button href="${Url.to("signup-page")}" variant="primary" type=${this.type} class="banner-btn">
              ${t("098dkjahsd89as9s.are-you-business-btn", "Let's Get Started!")}
            </fix-button>
          </div>
        </div>
      </div>`;
  }

  static styles = css`


    .main-banner {
      margin: 16px 8px;
      background-image: url('src/assets/main_banner.webp');
      background-size: cover;
      background-position: center;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
    }

    ${type("lg")} .main-banner    {
      height: 330px;
      margin: 0px;
    }
    
    .fader{
      background: rgba(0, 0, 0, 0.5);
      padding: 24px;
      text-align: center;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
    }

    ${type("lg")} .fader {
      padding: 24px 58px;
    }
    
    .fader .title {
      ${font('Heading/3')}
    }

    .fader .subtitle {
      ${font('Base/Normal')}
      margin-bottom: 8px;
    }

    ${type("lg")} .fader .subtitle {
      margin: 12px 0px;
      font-size: 19px;
    }

    ${type("lg")} .fader .title {
      font-size: 32px;
    }
    
    .btn-holder{
      
    }
    
    .banner-btn {
      display: inline-block;
    }
  `;
}
