import { MultiTypeElement, whenType } from "@/util/element";
import { css, html, LitElement, render, RenderOptions } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { ProductAreaStore } from "./product-area-store";
import { when } from "lit/directives/when.js";
import { Services } from "@/services/services";
import "./product-area-filters";
import "./product-area-tags";
import "./product-area-sorter";
import "./product-area-buttons";
import "./product-area-filter-popup";
import { cardDefault, columnsSidebarMain, layoutOffsetMargin, layoutOffsetPadding, provideLayoutStyles } from "@/pages/product-page/utils";
import { font } from "@/util/fonts";
import { Url } from "@/helpers/url";
import { B2cCategory, B2cProductSearchPayload } from "@/generated/graphql/b2c";
import { Categories } from "@/services/categories";
import { t } from "i18next";

@customElement("product-area-element")
export class ProductAreaElement extends MultiTypeElement {
  @property()
  type!: "sm" | "lg";

  @property()
  resultState: null | "empty" | "content" = null;

  @property({ hasChanged: () => true })
  store!: ProductAreaStore;

  @property({ hasChanged: () => true })
  services!: Services;

  render() {
    return choose(this.type, [
      ["sm", this.renderContentSm],
      ["lg", this.renderContentLg],
    ]);
  }

  renderContentSm = () => {
    const popup = this.renderActivePopup();

    return html`
      <div class="section card">
        <div class="section-title">Featured products</div>
        <div class="section-controls sorters">
          <filters-button-element></filters-button-element>
          <sorters-button-element></sorters-button-element>
        </div>
        <div class="section-box">${this.renderResults()}</div>
      </div>
      ${popup}
    `;
  };

  renderContentLg = () => {
    return html`
      <div class="card">
        <div class="sidebar">
          <filters-element-main .type=${"lg"} .filters=${this.store.filters}></filters-element-main>
        </div>
        <div class="main">${this.renderResults()}</div>
      </div>
    `;
  };

  renderActivePopup() {
    return choose(this.store.popup, [
      ["filters", () => this.renderFiltersPopup()],
      ["sorter", () => this.renderSorterPopup()],
    ]);
  }

  renderFiltersPopup() {
    return html`<product-area-filter-popup .store=${this.store} @close=${this.closePopup}></product-area-filter-popup>`;
  }

  renderSorterPopup() {
    return html`<product-area-sorter-popup .store=${this.store} @close=${this.closePopup}></product-area-sorter-popup>`;
  }

  renderResults() {
    return choose(this.resultState, [
      ["content", () => this.renderProducts()],
      ["empty", () => this.renderNoProducts()],
    ]);
  }

  renderProducts() {
    const items = this.store.products.map((item) => ({
      id: item.id,
      name: item.productInfo?.name ?? "",
      mpn: item.mpnFormatted,
      price: [item.minPrice, item.maxPrice],
      discount: 15,
      images: item.productAssets.filter((asset) => asset.type === "image"),
      url: createProductUrl(item, this.services.categories),
    }));

    const heading = when(this.type === "lg", () =>this.renderResultHeadingLg())

    return html`
          ${heading}
          <div class="grid">
            <product-grid-2 .data=${items} .type=${this.type}></product-grid-2>
          </div>
          ${this.renderPagination()}
        </div>
        `;
  }

  renderPagination() {
    if (this.store.pager.page === 1 && this.store.pager.totalItems < this.store.pager.pageSize) {
      return html``;
    }

    return html`
      <div class="pagination">
        <fix-pager-group
          .current=${this.store.pager.page}
          .total=${this.store.pager.total}
          .totalItems=${this.store.pager.total * this.store.pager.pageSize}
          .size=${this.store.pager.pageSize}
          .pager=${false}
          .next=${true}
          .type=${this.type}
        ></fix-pager-group>
      </div>
    `;
  }

  renderResultHeadingLg = () => {
    return html`
      <div class="top">
        <div class="selections">
          <tags-element .store=${this.store}></tags-element>
        </div>
        <div class="sorters">
          <sorter-element .store=${this.store}></sorter-element>
        </div>
      </div>
    `;
  };

  renderNoProducts() {
    const onBack = () => {
      this.store.backFromEmptySearch();
    };

    return html`
      <div class="no-products">
        <div class="text">${t("ct7yjv6q1dnhh9ng.not-found-in-category", "Sorry, there are currently no products in this category")}</div>
        <div class="subtext">${t("e96vlks4gy9wj8ex.check-back-later", "Please check back later")}</div>
        <fix-back-button class="back" @click=${onBack}></fix-back-button>
      </div>
    `;
  }

  createUrl(item: B2cProductSearchPayload) {
    return Url.to("product-page", { productId: item.id });
  }

  closePopup() {
    this.store.setCurrentPopup(null);
  }

  emitUpdateEvent() {
    this.dispatchEvent(new CustomEvent("update", {}));
  }

  connectedCallback(): void {
    super.connectedCallback();
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
  }

  static styleCommon = css`
    .no-products {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

    .no-products .text + .subtext {
      margin-top: 8px;
    }

    .no-products .text {
      color: var(--Neutral-Text-colorText, #27272a);
      ${font("LG/Strong")};
    }

    .no-products .subtext {
      color: var(--Neutral-Text-colorText, #27272a);
      ${font("Base/Normal")};
    }

    .no-products .back {
      margin-top: 16px;
    }
  `;

  static styleSm = css`
    ${provideLayoutStyles("sm")}

    .main {
      ${cardDefault()};
      ${layoutOffsetPadding()};
      padding-top: 24px;
      padding-bottom: 24px;
    }

    .title {
      ${font("Heading/4")};
      color: var(--Neutral-Text-colorText, #27272a);
    }

    .sorters {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  `;

  static styleLg = css`
    .no-found {
      text-align: center;
      padding: var(--space-padding-padding-md, 16px);
    }

    .topcard {
      ${cardDefault()};
      ${layoutOffsetPadding()};
      padding-top: 16px;
      padding-bottom: 16px;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    .card {
      ${cardDefault()};
      ${layoutOffsetMargin()};
      ${columnsSidebarMain({ mode: "solid" })};
      padding: var(--Space-Margin-marginXL, 32px);
      margin-bottom: 8px;
    }

    .sidebar {
      grid-area: sidebar;
    }

    .main {
      grid-area: main;
    }

    .main .top {
      width: 100%;
      display: flex;
      gap: 32px;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
    }

    .overlay {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 100;
    }

    .row-navigation {
      display: flex;
      align-items: center;
      gap: 48px;
    }

    .row-title {
      margin-top: 16px;
      margin-bottom: 16px;
    }

    .main:has(.no-products) {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `;

  static styles = [ProductAreaElement.styleCommon, whenType("sm", ProductAreaElement.styleSm), whenType("lg", ProductAreaElement.styleLg)];
}

const getProductCategory = (categories: Array<B2cCategory>, prefer = 'long') => {
  if (prefer === 'long') {
    return categories.slice().sort((a, b) => a.level - b.level)[0]?.id;
  }

  return categories.find((category) => category.id === prefer)?.id ?? categories[0]?.id;
};

export const createProductPath = (product: Pick<B2cProductSearchPayload, 'categories' | 'productInfo'>, categories: Categories, prefer = 'long') => {
  const category = getProductCategory(product.categories.filter(item => categories.getItem(item.id)), prefer);
  const slug = product.productInfo!.slug;
  const path = categories.getItemPath(category);

  return [...path, slug];
};

export const createProductUrl = (product: Pick<B2cProductSearchPayload, 'categories' | 'productInfo'>, categories: Categories, prefer = 'long') => {
  return Url.to("dynamic-route", { dynamicPath: createProductPath(product, categories) });
};