import { LitElement, css, html } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";

import "@/elements/fix-image";
import "@/elements/add-to-cart";
import "@/elements/view-details";
import { font } from "@/util/fonts";
import { type } from "@/util/layout";
import { consume } from "@lit/context";
import { Formatter, FormatterServiceContext } from "@/services/formatter";
import { when } from "lit/directives/when.js";
import { classMap } from "lit/directives/class-map.js";
import { ImageResizer } from "@/util/image-resizer.ts";
import {features} from "@/constants/features.ts";
import { CurrencyConverter } from "@/util/currency-converter.ts";

@customElement("product-element")
export class ProductElement extends LitElement {
  @property({ attribute: true, reflect: true, type: String })
  type: "lg" | "sm" | "md" = "lg";

  @property()
  name!: string;

  @property()
  mpn!: string;

  @property()
  price!: [number, number];

  @property()
  discount!: number;

  @property()
  images!: Array<{ path: string }>;

  @state()
  added = false;

  @query(".product-name")
  nameElement!: HTMLElement;

  @consume({ context: FormatterServiceContext, subscribe: true })
  formatter!: Formatter;

  @state()
  cursorPending = false;

  @state()
  clickPending = false;

  @property()
  href?: string;

  @property()
  mode: "cart" | "details" = "details";

  @query("a")
  link!: HTMLElement;

  render() {
    const actionClassNames = this.getActionClassNames();
    const classNames = classMap({
      container: true,
      ...actionClassNames,
    });

    const buttonClassNames = classMap({
      cart: true,
      ...actionClassNames,
    });

    const content = html`<div
      class=${classNames}
      @mouseenter=${this.onMouseEnter}
      @mouseleave=${this.onMouseLeave}
      @mousedown=${this.onMouseDown}
      @mouseup=${this.onMouseUp}
    >
      ${when(this.hasDiscount() && features.discount, () => html`<div class="discount">-${this.discount}%</div>`)}
      <div class="image">
        <fix-image class="image-item" type=${this.getImageType()} .src=${this.getImage()}></fix-image>
      </div>
      <div class="name">
        <div class="mpn">${this.mpn}</div>
        <div class="product-name">${this.name}</div>
      </div>
      <div class="price">${this.getPriceText()}</div>
      ${this.renderButton(buttonClassNames)}
    </div>`;

    return wrapToLink({
      content: content,
      href: this.href,
    });
  }

  renderButton(buttonClassNames: {}) {
    switch (this.mode) {
      case "cart":
        return html`<add-to-cart class=${buttonClassNames} type="sm" .done=${this.added} @click=${this.onCartClick}></add-to-cart>`;
      case "details":
        return html`<view-details class=${buttonClassNames} type="sm"></view-details>`;
    }
  }

  firstUpdated() {
    const element = this.nameElement;
    const realHeight = this.nameElement.clientHeight;
    const targetHeight = parseFloat(getComputedStyle(element).getPropertyValue("line-height"));
    if (realHeight > targetHeight) {
      element.parentElement!.classList.add("multiline");
    }
  }

  getImage() {
    return this.images?.[0]?.path ? ImageResizer.getProductImageUrl(this.images?.[0]?.path, "160x160") : null;
  }

  hasDiscount() {
    return (this.discount ?? 0) > 0;
  }

  getImageType() {
    return "sm" as const;
  }

  getPriceText() {
    const from = this.price[0];
    const to = this.price[1] ?? from;

    return this.formatter.priceRange([CurrencyConverter.convertCents(from), CurrencyConverter.convertCents(to)]);
  }

  onCartClick = () => {
    this.added = true;
  };

  onMouseEnter = () => {
    this.cursorPending = true;
  };

  onMouseLeave = () => {
    this.cursorPending = false;
    this.clickPending = false;
  };

  onMouseDown = (event: MouseEvent) => {
    this.updateClickPending(event, true);
  };

  onMouseUp = (event: MouseEvent) => {
    this.updateClickPending(event, false);
  };

  updateClickPending = (event: MouseEvent, state: boolean) => {
    if (event.button === 0) {
      this.clickPending = state;
    }
  };

  getActionClassNames(): Record<string, boolean> {
    if (this.clickPending) {
      return {
        "state-active": true,
      };
    }

    if (this.cursorPending) {
      return {
        "state-hover": true,
      };
    }

    return {};
  }

  static styles = css`
    ${type("md")} {
      --image-name-gap: 8px;
      --name-price-gap: 8px;
      --price-cart-gap: 0px;
      --box-padding-v: 15px;
      --box-padding-h: 15px;
      --image-percent: 57%;
    }

    ${type("md")} .price {
      ${font("Heading/5")};
    }

    ${type("md")} .name {
      ${font("Base/Normal")};
    }

    ${type("lg")} {
      --image-name-gap: 24px;
      --name-price-gap: 8px;
      --price-cart-gap: 8px;
      --box-padding-v: 15px;
      --box-padding-h: 15px;
      --image-percent: 74%;
    }

    ${type("lg")} .price {
      ${font("Heading/4")};
    }

    ${type("lg")} .name {
      ${font("LG/Normal")};
    }

    ${type("sm")} {
      --image-name-gap: 8px;
      --name-price-gap: 8px;
      --price-cart-gap: 8px;
      --box-padding-v: 15px;
      --box-padding-h: 15px;
      --image-percent: 77%;
    }

    ${type("sm")} .price {
      ${font("Heading/5")};
    }

    ${type("sm")} .name {
      ${font("LG/Normal")};
    }

    .image + .name {
      margin-top: var(--image-name-gap);
    }

    .name + .price {
      margin-top: var(--name-price-gap);
    }

    .price + .cart {
      margin-top: var(--price-cart-gap);
    }

    .container {
      background: var(--Product-card-colorBG, #fff);
      border-radius: var(--Border-Radius-borderRadius, 6px);
      border: 1px solid var(--Product-card-colorBorder, #d9d9d9);
      padding: var(--box-padding-v) var(--box-padding-h);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      position: relative;
      min-height: 100%;
      box-sizing: border-box;
      cursor: pointer;
      transition: border-color 50ms;
    }

    .container.state-hover,
    .container.state-active {
      border-color: var(--Product-card-colorBorderHover, #60a5fa);
    }

    .discount {
      position: absolute;
      z-index: 1;
      right: 1px;
      top: 1px;
      ${font("SM/Regular")};
      pointer-events: none;
      user-select: none;
    }

    .name,
    .price {
      text-align: left;
      min-width: 100%;
    }

    .image {
      width: var(--image-percent);
      min-width: min(80px, 100%);
    }

    .price {
      color: var(--Neutral-Text-colorTextHeading, #1f2937);
    }

    .name {
      color: var(--color-text-base, #18181b);
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .discount {
      border-radius: 0px 6px;
      padding: 0px 8px;
      background: var(--Brand-Discount-colorBg, #fb923c);
      color: var(--Neutral-colorWhite, #fff);
      width: 48px;
      box-sizing: border-box;
    }

    .name > * {
      display: block;
    }

    .name.multiline > * {
      display: inline;
    }

    .link-wrapper {
      text-decoration: none;
    }

    .link-wrapper,
    .container {
      display: flex;
      min-height: 100%;
      min-width: 100%;
    }
  `;
}

const wrapToLink = (params: { content: unknown; href?: string }) => {
  return html`<a class="link-wrapper" href=${params.href ?? "#"} draggable="false">${params.content}</a>`;
};
