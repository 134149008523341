import { HttpBackendOptions } from "i18next-http-backend";
import { InitOptions } from "i18next/typescript/options";

interface CustomInitOptions extends InitOptions {
  backend?: HttpBackendOptions;
}

const i18nOptions: CustomInitOptions = {
  backend: {
    loadPath: "/translations/{{lng}}.json",
  },
  debug: import.meta.env.DEV,
  lng: "en",
  fallbackLng: "en",
};

export default i18nOptions;
