import { VirtualElement } from "@shoelace-style/shoelace/dist/components/popup/popup.component";
import { adoptStyles, css, html, LitElement, nothing, PropertyValues, unsafeCSS } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ProductAreaStore } from "./product-area-store";
import { font } from "@/util/fonts";
import { OverlayScrollbars, ClickScrollPlugin } from "overlayscrollbars";
import bars from "overlayscrollbars/overlayscrollbars.css?inline";
import "./product-area-filters";
import { repeat } from "lit/directives/repeat.js";
import { when } from "lit/directives/when.js";
import doneSvg from "@/assets/check.svg";
import { FilterForm } from "@/elements/filter-form/form";
import { t } from "i18next";
import { classMap } from "lit/directives/class-map.js";
import "@/elements/fix-button-default";
import "@/elements/fix-button-primary";

OverlayScrollbars.plugin([ClickScrollPlugin]);

export abstract class GlobalElement extends LitElement {
  outlet!: HTMLElement;

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    const div = document.createElement("div");

    document.body.appendChild(div);
    const root = div.attachShadow({ mode: "open" });

    adoptStyles(root, (this.constructor as typeof LitElement).elementStyles!);

    this.outlet = div;
    return root;
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.destroyOutlet();
  }

  destroyOutlet() {
    document.body.removeChild(this.outlet);
  }
}

abstract class PopupElement extends GlobalElement {
  anchor!: string | Element | VirtualElement;
  scrollLocking = false;
  @state()
  popupVisible = true;
  @state()
  backdropVisible = false;

  render() {
    if (!this.popupVisible) {
      return nothing;
    }

    return html` ${when(this.backdropVisible, () => this.renderBackdrop())} ${this.renderPopup()} `;
  }

  renderBox(): unknown {
    return nothing;
  }

  renderPopup() {
    return html` <sl-popup .anchor=${this.anchor} placement="bottom" strategy="fixed" active> ${this.renderBox()} </sl-popup> `;
  }

  renderBackdrop() {
    return html`<backdrop-area @click=${() => this.onBackdropClick()}></backdrop-area>`;
  }

  onBackdropClick() { }

  connectedCallback(): void {
    super.connectedCallback();
    this.lockScrolling(true);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.lockScrolling(false);
  }

  lockScrolling(state: boolean) {
    if (this.scrollLocking) {
      document.body.style.overflow = state ? "hidden" : null!;
    }
  }

  getScreenVirtualElement(): VirtualElement {
    return this.getFullscreenVirtualElement();
  }

  getFullscreenVirtualElement(): VirtualElement {
    return {
      getBoundingClientRect: () => {
        return {
          bottom: window.screen.height,
          height: window.screen.height,
          left: 0,
          right: window.screen.width,
          top: 0,
          width: window.screen.width,
          x: 0,
          y: 0,
          toJSON: () => { },
        };
      },
    };
  }

  static boxStyles = css`
    .close::part(base) {
      --sl-color-primary-700: #52525B;
      --sl-color-neutral-600: #27272A;
      font-size: 24px;
    }
  `;
}

abstract class SidePopup extends PopupElement {
  backdropVisible = true;
  scrollLocking = true;
  segments = ["header", "content", "footer"];

  @query("backdrop-area")
  backdropAreaElement!: HTMLElement;

  @query(".box")
  boxElement!: HTMLElement;

  anchor = this.getScreenVirtualElement();

  renderBox(): unknown {
    return html`
      <div class="box">
        ${when(this.segments.includes("header"), () => this.renderHeader())} ${when(this.segments.includes("content"), () => this.renderContent())}
        ${when(this.segments.includes("footer"), () => this.renderFooter())}
      </div>
    `;
  }

  renderHeader(): unknown {
    return html`
      <div class="header">
        <div class="title">Filters</div>
        <sl-icon-button class="close" name="x" @click=${() => this.emitClose()}></sl-icon-button>
      </div>
    `;
  }

  renderContent(): unknown {
    return nothing;
  }

  renderFooter(): unknown {
    return nothing;
  }

  emitClose() {
    this.dispatchEvent(new CustomEvent("close"));
  }

  async startAnimation(type: "open" | "close") {
    this.boxElement.style.pointerEvents = 'none';

    const options = {
      duration: 230,
      direction: type === "open" ? ("normal" as const) : ("reverse" as const),
      fill: 'forwards' as const
    };
    const move = { transform: ["translateX(-100%)", "translateX(0)"] };
    const fade = { opacity: [0.8, 1] };

    const backdropAnimation = this.backdropAreaElement.animate(
      {
        ...fade,
      },
      { ...options },
    );
    const boxAnimation = this.boxElement.animate(
      {
        ...fade,
        ...move,
      },
      { ...options },
    );

    if (type === "open") {
      this.popupVisible = true;
    }

    await Promise.all([backdropAnimation.finished, boxAnimation.finished]);

    if (type === 'close') {
      this.popupVisible = false;
    }

    this.boxElement.style.pointerEvents = 'auto';
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    this.startAnimation("open");
  }
}

abstract class BottomOptionsPopup extends PopupElement {
  backdropVisible = true;
  scrollLocking = true;

  anchor = this.getScreenVirtualElement();

  @query("backdrop-area")
  backdropAreaElement!: HTMLElement;

  @query(".box")
  boxElement!: HTMLElement;

  @query(".inner")
  scrollable!: HTMLElement;

  renderBox(): unknown {
    return html` <div class="box">${this.renderHeader()} ${this.renderContent()}</div> `;
  }

  renderHeader(): unknown {
    return html`
      <div class="header">
        <div class="title">Sort by</div>
      </div>
    `;
  }

  renderContent(): unknown {
    return html`
      <div class="content">
        <div class="inner">${this.renderOptionsList()}</div>
      </div>
    `;
  }

  renderOptionsList(): unknown {
    return nothing;
  }

  async startAnimation(type: "open" | "close") {
    const options = {
      duration: 120,
      direction: type === "open" ? ("normal" as const) : ("reverse" as const),
      fill: 'forwards' as const
    };
    const move = { transform: ["translateY(100%)", "translateY(0)"] };
    const fade = { opacity: [0.8, 1] };

    const backdropAnimation = this.backdropAreaElement.animate(
      {
        ...fade,
      },
      { ...options },
    );
    const boxAnimation = this.boxElement.animate(
      {
        ...fade,
        ...move,
      },
      { ...options },
    );

    if (type === "open") {
      this.popupVisible = true;
    }

    await Promise.all([backdropAnimation.finished, boxAnimation.finished]);

    if (type === 'close') {
      this.popupVisible = false;
    }
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);
    OverlayScrollbars(this.scrollable, {
      //@ts-expect-error
      plugins: {
        clickScroll: { enable: true },
      },
    });
    this.startAnimation("open");
  }

  emitClose() {
    this.dispatchEvent(new CustomEvent("close"));
  }

  async onBackdropClick() {
    this.startAnimation("close");
    this.emitClose();
  }

  static contentStyles = css`
  .content,
  .os-scrollbar.os-theme-dark,
  .os-scrollbar.os-theme-light {
    --os-handle-bg: var(--Base-Gray-6, #71717a);
    --os-track-bg: var(--Base-Gray-2, #f4f4f5);
    --os-handle-border-radius: var(--Border-Radius-borderRadiusLG, 8px);
    --scroll-width: 6px;
  }

  .box {
    background: var(--Neutral-Bg-colorBgContainer, #fff);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    height: auto;
    border-bottom-right-radius: 6px;
  }

  .header {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--Size-Line-Width-lineWidth, 1px) solid var(--Neutral-Border-colorBorderSecondary, #f0f0f0);
    flex-grow: 0;
  }

  .title {
    ${font("Heading/4")};
    color: var(--color-text-heading, #111827);
  }

  .content {
    padding: 20px 8px 20px 0px;
    flex-grow: 1;
    overflow: hidden;
  }

  .content .inner {
    max-height: 100%;
    padding-right: 24px;
    padding-left: 24px;
  }

  .options {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .option {
    border-radius: 6px;
    padding: 8px 8px 8px 16px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  .option .name {
    ${font("Base/Normal")};
    color: var(--Components-Typography-Global-colorText, #27272a);
  }

  .option.selected {
    background: var(--Brand-Primary-colorPrimaryBg, #eff6ff);
  }

  .option sl-icon {
    color: #65a30d;
    font-size: 20px;
  }

  sl-popup::part(popup) {
    z-index: 101;
    transform: translateY(-100%);
    height: auto;
    width: 100%;
    right: 8px;
    left: 8px;
    width: calc(100% - 16px);
  }

  .box {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px 8px;
  }

  .title {
    ${font("Heading/3")};
    color: var(--color-text-heading, #111827);
    padding-left: 16px;
    padding-right: 16px;
  }

  .header {
    border-bottom: none;
    padding: 0;
  }

  .header sl-icon-button {
    display: none;
  }

  .content {
    padding: 0;
  }

  .content .inner {
    padding: 0;
  }

  .option {
    border-radius: 6px;
  }

  .option.selected,
  .option:hover,
  .option:active {
    background: var(--Brand-Primary-colorPrimaryBg, #EFF6FF);
  }
`;

  static styles = [BottomOptionsPopup.boxStyles, BottomOptionsPopup.contentStyles, unsafeCSS(bars)];
}

@customElement("product-area-filter-popup")
export class ProductAreaFilterPopup extends SidePopup {
  @property({ hasChanged: () => true })
  store!: ProductAreaStore;

  filters: FilterForm = new FilterForm({ autoApply: false });

  @query(".inner")
  scrollable!: HTMLElement;

  renderContent(): unknown {
    return html`
      <div class="content" @filters.applied=${this.passEvent} @filters.clear=${this.passEvent}>
        <div class="inner">
          <filters-element-main .type=${"sm"} .filters=${this.filters}></filters-element-main>
        </div>
      </div>
    `;
  }

  renderFooter(): unknown {
    return html`
      <div class="footer">
        <fix-button-default @click=${this.onClear} .variant=${'default'}>${t("38xtmz11ad14blsh.clear-all", "Clear All")}</fix-button-default>
        <fix-button-primary @click=${this.onApply} .variant=${'primary'}>Apply</fix-button-primary>
      </div>
    `;
  }

  protected onApply = () => {
    this.filters.applied();
    this.close();
  }

  protected onClear = () => {
    this.filters.cleared();
    this.close();
  }

  protected async close() {
    await this.startAnimation("close");
    this.dispatchEvent(new CustomEvent("close"));
  }

  protected passEvent(event: CustomEvent) {
    this.dispatchEvent(new CustomEvent(event.type, { bubbles: true, composed: true, detail: event.detail }));
  }

  protected firstUpdated(_changedProperties: PropertyValues): void {
    super.firstUpdated(_changedProperties);

    OverlayScrollbars(this.scrollable, {
      //@ts-expect-error
      plugins: {
        clickScroll: { enable: true },
      },
    });
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.store.connectForm(this.filters, true);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.store.connectForm(this.filters, false);
  }

  static contentStyles = css`
    .content,
    .os-scrollbar.os-theme-dark,
    .os-scrollbar.os-theme-light {
      --os-handle-bg: var(--Base-Gray-6, #71717a);
      --os-track-bg: var(--Base-Gray-2, #f4f4f5);
      --os-handle-border-radius: var(--Border-Radius-borderRadiusLG, 8px);
      --scroll-width: 6px;
    }

    sl-popup::part(popup) {
      z-index: 101;
      transform: translateY(-100%);
      height: 100%;
      width: 100%;
      right: 20px;
      left: 0px;
      width: calc(100% - 20px);
    }

    .box {
      background: var(--Neutral-Bg-colorBgContainer, #fff);
      z-index: 1000;
      display: flex;
      flex-direction: column;
      height: 100%;
      border-bottom-right-radius: 6px;
    }

    .header {
      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: var(--Size-Line-Width-lineWidth, 1px) solid var(--Neutral-Border-colorBorderSecondary, #f0f0f0);
      flex-grow: 0;
    }

    .title {
      ${font("Heading/4")};
      color: var(--color-text-heading, #111827);
    }

    .content {
      padding: 20px 8px 20px 0px;
      flex-grow: 1;
      overflow: hidden;
    }

    .content .inner {
      max-height: 100%;
      padding-right: 24px;
      padding-left: 24px;
    }

    .footer {
      border-top: var(--Size-Line-Width-lineWidth, 1px) solid var(--Neutral-Border-colorBorderSecondary, #f0f0f0);

      padding: 16px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      flex-grow: 0;
    }

    .footer > * {
      flex-basis: 50%;
    }
  `;

  static styles = [ProductAreaFilterPopup.boxStyles, ProductAreaFilterPopup.contentStyles, unsafeCSS(bars)];
}

@customElement("product-area-sorter-popup")
export class ProductAreaSorterPopup extends BottomOptionsPopup {
  @property({ hasChanged: () => true })
  store!: ProductAreaStore;

  filters: FilterForm = new FilterForm({ autoApply: false });

  renderOptionsList() {
    const options = this.filters.getOptions("sort");
    const items = repeat(
      options,
      (item) => item.id,
      (item) => this.renderOption(item),
    );

    return html` <div class="options">${items}</div> `;
  }

  renderOption(item: { key: string; id: string; name: string; selected: boolean }) {
    const className = classMap({
      option: true,
      selected: item.selected,
    });
    const onClick = () => this.onSelect(item.id);

    return html`
      <div class=${className} @click=${onClick}>
        <div class="name">${item.name}</div>
        ${when(item.selected, () => html` <sl-icon class="icon" src=${doneSvg}></sl-icon> `)}
      </div>
    `;
  }

  async onSelect(id: string) {
    this.filters.selectSort(id);
    this.filters.applied();
    this.startAnimation("close");
    this.emitClose();
  }

  connectedCallback(): void {
    super.connectedCallback();
    this.store.connectForm(this.filters, true);
    this.filters.setHost(this);
  }

  disconnectedCallback(): void {
    super.disconnectedCallback();
    this.store.connectForm(this.filters, false);
  }
}
